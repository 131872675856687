import styled, { css } from "styled-components";
import {
  AppContainer,
  LinkBase,
  LinksWrapper,
  TextNormal,
} from "../../styles/BasicStyles";
import { mediaDown } from "../../styles/Mixins";
import ThemeStyle from "../../styles/ThemeStyle";

export const Footer = styled.footer`
  font-size: 1.6rem;
  background: #fff;

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      background: transparent;
      position: absolute;
      bottom: 10rem;
      width: 100%;
    `
  )};
`;

export const Container = styled.div`
  ${AppContainer()};
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    height: 4rem;
  }
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      flex-flow: column;
      justify-content: center;
      .logo-tullamore {
        display: none;
      }
    `
  )};
`;

export const FooterLinks = styled(LinksWrapper)`
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      flex-flow: column;
      margin-left: unset;
    `
  )};
`;

export const Link = styled(LinkBase)`
  text-transform: none;
  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      margin: 0 2rem;
    `
  )}
`;

export const Button = styled.button`
  position: relative;
  color: ${ThemeStyle.colors.green};
  text-transform: none;
  margin: 0 4rem;
  font-weight: 700;
  padding: 1.5rem 0;
  font-size: 1.6rem;
  line-height: 1;
  transition: ${ThemeStyle.transition};
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1rem;
    overflow: hidden;
    height: 0.1rem;
    width: 100%;
    opacity: 0;
    background-color: ${ThemeStyle.colors.green};
    transition: ${ThemeStyle.transition};
    transition-property: bottom, opacity;
  }
  &:hover {
    &:before {
      bottom: 1rem;
      opacity: 1;
    }
  }
  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      margin: 0 2rem;
    `
  )}
`;

export const Date = styled.p`
  ${TextNormal};
  font-size: 1.6rem;
  margin: 0 0 0 2rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      display: none;
    `
  )}
`;
