import { createGlobalStyle } from "styled-components";
import ThemeStyle from "./ThemeStyle";
import { cookiesGlobalStyles } from "../components/CookieConsent/CookieConsent.styled";
import Background from "../images/Common/bg-light.jpg";

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }


  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
    background: url("${Background}") center/cover no-repeat;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font: inherit;
  }

  select {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }

  input {
    background-color: transparent;
    border: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #FAF4E2 inset !important;
    -webkit-text-fill-color: ${ThemeStyle.colors.green} !important;
    animation: onAutoFillStart 5s ease-in-out;
  }
  
  @keyframes onAutoFillStart {
    from {
      background-color: #FAF4E2;
    }
    to {
      background-color: #FAF4E2;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  html {
    scroll-behavior: smooth !important;
    font-size: 10px;
  }
  
  @font-face {
    font-family: trade-gothic-next-compressed, sans-serif;
    font-style: normal;
  }
  
  body {
    position: relative;
    font-style: normal;
    font-family: ${ThemeStyle.fonts.main};
    font-kerning: normal;
    background-color: ${ThemeStyle.colors.beige};
    font-size: 1.6rem;
    letter-spacing: 0.025em;

    * {
      box-sizing: border-box;
    }

    a {
      cursor: pointer;
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }

    img {
      max-width: 100%;
    }
  }

  ${cookiesGlobalStyles}
`;

export default GlobalStyle;
