import React, { FunctionComponent } from "react";
import * as Styled from "./MobileMenu.styled";
import BurgerMenuIcon from "../Icons/BurgerMenuIcon";
import Logo from "../../Logo/Logo";
import scrollToElement from "../../../functions/scrollToElement";

interface MobileMenuProps {
  isMenuOpen: boolean;
  handleMenuToggle: () => void;
}

const linksVariants = {
  visible: {
    transition: {
      staggerChildren: 0.05,
      delayChildren: 0.02,
    },
  },
};

const linkVariants = {
  visible: {
    x: 0,
    opacity: 1,
  },

  hidden: {
    x: 70,
    opacity: 0,
  },
};

const MobileMenu: FunctionComponent<MobileMenuProps> = ({
  isMenuOpen,
  handleMenuToggle,
}) => {
  return (
    <Styled.Menu isMenuOpen={isMenuOpen}>
      <Styled.TopWrapper>
        <Logo dark={true} width="85px" />
        <Styled.MenuButton onClick={handleMenuToggle}>
          <BurgerMenuIcon isMenuOpen={isMenuOpen} dark />
        </Styled.MenuButton>
      </Styled.TopWrapper>
      <Styled.Container>
        <Styled.Links variants={linksVariants}>
          <Styled.Link
            onClick={(e) => {
              scrollToElement(e, "tullamore");
              handleMenuToggle();
            }}
            href="#"
            variants={linkVariants}
          >
            Tullamore D.E.W. Honey
          </Styled.Link>
          <Styled.Link
            onClick={(e) => {
              scrollToElement(e, "konkurs");
              handleMenuToggle();
            }}
            href="#"
            variants={linkVariants}
          >
            Konkurs
          </Styled.Link>
          <Styled.Link
            onClick={(e) => {
              scrollToElement(e, "nagrody");
              handleMenuToggle();
            }}
            href="#"
            variants={linkVariants}
          >
            Nagrody
          </Styled.Link>
          <Styled.Link
            onClick={(e) => {
              scrollToElement(e, "wez-udzial");
              handleMenuToggle();
            }}
            href="#"
            variants={linkVariants}
          >
            Weź udział
          </Styled.Link>
          <Styled.Link
            onClick={(e) => {
              scrollToElement(e, "koktajle");
              handleMenuToggle();
            }}
            href="#"
            variants={linkVariants}
          >
            Koktajle
          </Styled.Link>
          <Styled.Link
            onClick={(e) => {
              scrollToElement(e, "kontakt");
              handleMenuToggle();
            }}
            href="#"
            variants={linkVariants}
          >
            Kontakt
          </Styled.Link>
        </Styled.Links>
      </Styled.Container>
    </Styled.Menu>
  );
};

export default MobileMenu;
