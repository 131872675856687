import styled, { css } from "styled-components";
import {
  AppContainer,
  TextLarge,
  TextNormal,
  TitleLarge,
} from "../../styles/BasicStyles";
import InfoBg from "../../images/HeaderSection/infoscreen-bg.jpg";
import ThemeStyle from "../../styles/ThemeStyle";
import { mediaDown } from "../../styles/Mixins";
import LandscapeStatic from "../../images/Common/landscape-background.jpg";
import LandscapeStaticMobile from "../../images/Common/landscape-background-mobile.jpg";
import BottleGif from "../../images/HeaderSection/bottle.gif";
import BeeLargeGif from "../../images/Common/bee-large.gif";
import BeeLargeGifMobile from "../../images/Common/bee-large-mobile.gif";
import BeeSmallGif from "../../images/Common/bee-small.gif";

export const Header = styled.header`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${ThemeStyle.colors.beige} url(${InfoBg}) center/cover no-repeat;
`;

export const LandscapeImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: url(${LandscapeStatic}) center/cover no-repeat;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  transition-property: opacity, visibility, transform;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      background: url(${LandscapeStaticMobile}) bottom/cover no-repeat;
    `
  )}
`;

export const Bottle = styled.div`
  position: absolute;
  top: 60vh;
  left: 50%;
  transform: translate(-50%, 0);
  min-width: 23.5rem;
  height: 67.6rem;
  max-height: 80vh;
  background: url(${BottleGif}) center/contain no-repeat;
  z-index: 5;

  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      top: 50vh;
      min-width: 20rem;
      height: 66rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      top: 60vh;
      max-height: 50vh;
      min-width: 15rem;
      height: 51rem;

      &.animated {
        top: 50vh;
      }
    `
  )}
`;

export const Landscape = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  z-index: 2;
  overflow: hidden;
`;

export const LandscapeContainer = styled.div`
  ${AppContainer()};
  position: relative;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

export const BeeLarge = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url(${BeeLargeGif}) center/contain no-repeat;
  width: 63rem;
  height: 63rem;
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      width: 40rem;
      height: 40rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      width: 33rem;
      height: 33rem;
      transform: translateY(-20%);
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      background: url(${BeeLargeGifMobile}) center/contain no-repeat;
      width: 15rem;
      height: 15rem;
      transform: translateY(-15%);
    `
  )}
`;

export const BeeSmall = styled.div`
  position: absolute;
  top: 20%;
  right: 20%;
  background: url(${BeeSmallGif}) center/contain no-repeat;
  width: 12rem;
  height: 12rem;

  &.info-container {
    right: 0;
  }

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      display: none;
    `
  )}
`;

export const LandscapeTextWrapper = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%) !important;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      top: 18%;
    `
  )}
`;

export const LandscapeTitle = styled.h2`
  ${TitleLarge};
  font-size: 6.4rem;
  margin-bottom: 2rem;
  text-align: center;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 4.2rem;
    `
  )}
`;

export const LandscapeText = styled.p`
  ${TextLarge};
  font-size: 2.2rem;
  text-align: center;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.8rem;
    `
  )}
`;

export const Info = styled.div`
  width: 100%;
  padding: 30rem 0 10rem;
  z-index: 1;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 20rem 0 50rem;
    `
  )}
`;

export const InfoContainer = styled.div`
  ${AppContainer()};
  max-width: 1080px;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

export const InfoTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: space-between;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      flex-flow: column;
      div:first-of-type {
        margin-bottom: 4rem;
      }
    `
  )}
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 30rem;
`;

export const InfoTitle = styled.h1`
  ${TitleLarge};
  margin-bottom: 9rem;
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      font-size: 15rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 12rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 5.4rem;
    `
  )}
`;

export const InfoSubtitle = styled.h2`
  ${TextLarge};
  margin-bottom: 2rem;
`;

export const InfoText = styled.p`
  ${TextNormal};
  font-size: 2rem;
`;
