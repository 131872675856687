import React, { FunctionComponent } from "react";
import NavStatic from "./Nav/NavStatic";
import MobileMenu from "./MobileMenu/MobileMenu";
import NavAnimated from "./Nav/NavAnimated";
import useScroll from "../../hooks/useScroll";

type Props = {};

const Navigation: FunctionComponent<Props> = (props) => {
  const [showStatic, setShowStatic] = React.useState(false);
  const [showAnimated, setShowAnimated] = React.useState(false);

  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const { top, directionVertical } = useScroll();

  React.useEffect(() => {
    if (top < 500) {
      setShowAnimated(false);
      setShowStatic(true);
      return;
    }

    if (directionVertical === "up") {
      setShowAnimated(true);
    } else {
      setShowAnimated(false);
    }
  }, [top, directionVertical]);

  const handleMenuToggle = React.useCallback(() => {
    setIsMenuOpen((v) => !v);
  }, []);

  React.useEffect(() => {
    setShowStatic(true);
  }, []);

  return (
    <>
      {showStatic && (
        <>
          <NavStatic
            isMenuOpen={isMenuOpen}
            handleMenuToggle={handleMenuToggle}
          />
          <NavAnimated
            isMenuOpen={isMenuOpen}
            handleMenuToggle={handleMenuToggle}
            isVisible={showAnimated}
          />
          <MobileMenu
            handleMenuToggle={handleMenuToggle}
            isMenuOpen={isMenuOpen}
          />
        </>
      )}
    </>
  );
};

export default Navigation;
