import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";

export const cookiesGlobalStyles = css`
  :root {
    --cc-btn-primary-bg: ${ThemeStyle.colors.green};
    --cc-btn-primary-hover-bg: ${ThemeStyle.colors.yellow};
    --cc-btn-secondary-bg: #e0d5a4;
    --cc-btn-secondary-hover-bg: ${ThemeStyle.colors.yellow};
    --cc-btn-secondary-text: ${ThemeStyle.colors.white};
    --cc-text: ${ThemeStyle.colors.green};
    --cc-btn-primary-text: var(--cc-bg);
    --cc-toggle-bg-off: ${ThemeStyle.colors.white};
    --cc-toggle-bg-on: var(--cc-btn-primary-bg);
    --cc-toggle-bg-readonly: #d5dee2;
    --cc-toggle-knob-bg: ${ThemeStyle.colors.green};
    --cc-toggle-knob-icon-color: #ecf2fa;
    --cc-block-text: var(--cc-text);
    --cc-cookie-category-block-bg: ${ThemeStyle.colors.beigeDark};
    --cc-cookie-category-block-bg-hover: ${ThemeStyle.colors.beigeDark};
    --cc-section-border: #f1f3f5;
    --cc-cookie-table-border: #e9edf2;
    --cc-overlay-bg: rgba(4, 6, 8, 0.85);
    --cc-webkit-scrollbar-bg: ${ThemeStyle.colors.green};
    --cc-webkit-scrollbar-bg-hover: ${ThemeStyle.colors.green};
  }

  .cc_div {
    font-family: inherit;
  }

  .cloud #c-ttl {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 800;
  }

  #cc_div #cm,
  #cm.slide,
  .cc_div .slide #s-inr {
    border-radius: 0;
  }

  .cc_div .cloud #c-txt {
    font-size: 1.6rem;
  }

  #c-bns .c-bn,
  #s-bns .c-bn {
    position: relative;
    font-size: 2rem;
    padding: 1.5rem 1rem;
    font-weight: 800;
    border-radius: 0;
    transition: ${ThemeStyle.transition} !important;
    transition-property: background-color !important;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: -2rem;
      background-color: var(--cc-btn-primary-bg);
      clip-path: polygon(100% 50%, 0 0, 0 100%);
      overflow: hidden;
      height: 100%;
      width: 2rem;
      transform: rotate(180deg);
      transition: ${ThemeStyle.transition};
      transition-property: background-color;
    }
    &:after {
      left: unset;
      right: -2rem;
      transform: translateX(-2%);
    }
  }

  #c-bns .c-bn:hover,
  #s-bns .c-bn:hover {
    &:before,
    &:after {
      background-color: var(--cc-btn-primary-hover-bg);
    }
  }

  #c-bns .c-bn.c_link,
  #s-cnt #s-rall-bn,
  #s-cnt #s-sv-bn {
    &:before,
    &:after {
      background-color: var(--cc-btn-secondary-bg);
    }
  }

  #s-cnt #s-rall-bn:hover,
  #s-cnt #s-sv-bn:hover {
    &:before,
    &:after {
      background-color: var(--cc-btn-secondary-hover-bg);
    }
  }

  #c-bns .c-bn.c_link:hover {
    &:before,
    &:after {
      background-color: var(--cc-btn-secondary-hover-bg);
    }
  }

  #s-cnt #s-all-bn,
  #s-cnt #s-rall-bn,
  #s-cnt #s-sv-bn {
    margin: 0 2rem;
    @media only screen and (max-width: 688px) {
      width: 60%;
      justify-self: center;
    }
  }

  #s-cnt #s-rall-bn {
    margin: 0 2rem 0 3rem;
    @media only screen and (max-width: 688px) {
      margin: 0 2rem;
    }
  }

  .cc_div .cc-link {
    margin-left: 0.1rem;
    transition: all 0.3s ease;
    transition-property: border-color, color;
  }
  .cc_div .cc-link:active,
  .cc_div .cc-link:hover {
    color: ${ThemeStyle.colors.yellow};
    border-color: ${ThemeStyle.colors.yellow};
  }

  #s-bl .c-bl:last-of-type:hover {
    background: #fff;
  }

  .cc_div #c-bns {
    margin-top: 0;
  }

  .cc_div .cloud #c-inr {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 688px) {
      flex-flow: column;
    }
  }

  .cc_div .cloud #c-bns {
    padding: 0 2rem;
  }

  #s-ttl {
    font-size: 2.8rem;
    font-weight: 800;
    text-transform: uppercase;
  }

  .cc_div .b-tg .c-tgl:checked ~ .c-tg {
    background: #fff;
  }

  .cc_div .b-tg .c-tgl ~ .c-tg.c-ro {
    background: #eeeeee;
  }

  .cc_div .on-i {
    &:before {
      border-color: #d6cca3;
    }
  }

  .cc_div .off-i {
    &:before,
    &:after {
      background-color: #d6cca3;
    }
  }

  #s-bns {
    height: unset;
    @media only screen and (max-width: 600px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      justify-content: center;
      align-items: center;
    }
  }

  #s-cnt .b-bn .b-tl {
    font-size: 1.8rem;
  }

  #s-bl .title,
  #s-bl .p {
    font-size: 1.8rem;
  }

  .cc_div .b-bn .exp::before,
  .cc_div .act .b-bn .b-tl::before {
    border-color: ${ThemeStyle.colors.green};
  }

  .bns-t #s-bns {
    @media screen and (max-width: 688px) {
      height: unset;
    }
  }
`;
