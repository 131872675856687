import React, { FC, ReactElement, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/GlobalStyle";
import ThemeStyle from "../../styles/ThemeStyle";
import HeaderSection from "../../sections/HeaderSection/HeaderSection";
import Footer from "../../components/Footer/Footer";
import Seo from "../../components/Seo/Seo";
import Navigation from "../../components/Navigation/Navigation";
import CookieConsent from "../../components/CookieConsent";
import AgeCheckerSection from "../../sections/AgeChecker/AgeCheckerSection";

interface IMainLayout {
  children: ReactElement | ReactElement[];
}

const MainLayout: FC<IMainLayout> = ({ children }) => {
  const [legalAge, setLegalAge] = React.useState<boolean>(false);

  useEffect(() => {
    const body = document.getElementsByTagName("body");
    if (!legalAge && body) {
      body[0].style.overflow = "hidden";
    } else if (legalAge && body) {
      body[0].style.overflow = "unset";
    }
  }, [legalAge]);
  return (
    <ThemeProvider theme={ThemeStyle}>
      <GlobalStyle />
      {!legalAge && <AgeCheckerSection onChange={setLegalAge} />}
      <div>
        <Navigation />
        <Seo />
        <HeaderSection />
        <main className="main-content">{children}</main>
        <Footer />
        <CookieConsent />
      </div>
    </ThemeProvider>
  );
};

export default MainLayout;
