import styled, { css } from "styled-components";
import ThemeStyle from "../../../styles/ThemeStyle";
import { mediaUp, mediaDown } from "../../../styles/Mixins";
import { motion } from "framer-motion";
import { TextNormal } from "../../../styles/BasicStyles";
import background from "../../../images/MobileMenu/menu-mobile-bg.jpg";

type MobileMenuType = {
  isMenuOpen: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

export const Menu = styled.div<MobileMenuType>`
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  transform: translateX(100%);
  min-height: 100vh;
  display: block;
  width: 100%;
  padding: 3rem 2rem;
  background-color: ${ThemeStyle.colors.white};
  z-index: 1000;
  transition: ${ThemeStyle.transition};
  transition: ${ThemeStyle.transition};
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  -webkit-box-shadow: -17px 8px 43px 0 rgba(66, 68, 90, 0.2);
  -moz-box-shadow: -17px 8px 43px 0 rgba(66, 68, 90, 0.2);
  box-shadow: -17px 8px 43px 0 rgba(66, 68, 90, 0.2);

  ${(props) =>
    props.isMenuOpen &&
    css`
      transform: translateX(0);
      opacity: 1;
    `}

  ${mediaUp(
    ThemeStyle.breakpoints.nav,
    css`
      display: none;
    `
  )};
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .logo {
    margin-left: 1rem;

    ${mediaUp(
      ThemeStyle.breakpoints.sm,
      css`
        margin-left: 3rem;
      `
    )};
  }
`;

export const MenuButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border: none;
  outline: none;
  z-index: 1;
  width: 100%;

  ${mediaUp(
    ThemeStyle.breakpoints.nav,
    css`
      display: none;
    `
  )};
`;

export const Links = styled(motion.ul)`
  margin: 2rem auto 1rem;
  height: auto;
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 20rem;
  text-align: center;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      max-width: 40rem;
    `
  )};
`;

export const Separator = styled(motion.div)`
  height: 1px;
  width: 100%;
  background-color: #c6c6c6;
  margin-bottom: 1.5rem;
`;

export const Link = styled(motion.a)`
  ${TextNormal};
  display: block;
  margin: 1.2rem 0;
  font-size: 1.8rem;
  cursor: pointer;
  padding-bottom: 2.4rem;
  border-bottom: 1px solid ${ThemeStyle.colors.green};
  transition: ${ThemeStyle.transition};

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 2.8rem;
    `
  )};

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    color: ${ThemeStyle.colors.blackHover};
  }
  &:focus {
    color: ${ThemeStyle.colors.blackHover};
  }
`;
