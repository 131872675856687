import React, { FunctionComponent } from "react";

type BurgerMenuIconProps = {
  isMenuOpen?: boolean;
  dark?: boolean;
};

const BurgerMenuIcon: FunctionComponent<BurgerMenuIconProps> = ({
  isMenuOpen,
  dark,
}) => {
  return (
    <svg width="34" height="22" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="34"
        height="2"
        y={isMenuOpen ? "10" : "0"}
        fill={dark ? "#251D17" : "#E5E1D5"}
        transform={isMenuOpen ? "rotate(45)" : "rotate(0)"}
        style={{ transformOrigin: "center", transition: "0.2s" }}
      />
      <rect
        x={isMenuOpen ? "15" : "0"}
        y="10"
        width={isMenuOpen ? "0" : "34"}
        height="2"
        fill={dark ? "#251D17" : "#E5E1D5"}
        style={{ transformOrigin: "center", transition: "0.4s" }}
      />
      <rect
        x={isMenuOpen ? "0" : "0"}
        y={isMenuOpen ? "10" : "20"}
        width={isMenuOpen ? "34" : "34"}
        height="2"
        fill={dark ? "#251D17" : "#E5E1D5"}
        transform={isMenuOpen ? "rotate(-45)" : "rotate(0)"}
        style={{ transformOrigin: "center", transition: "0.2s" }}
      />
    </svg>
  );
};

export default BurgerMenuIcon;
