import React from "react";
import * as Styled from "./Footer.styled";
import Logo from "../Logo/Logo";

const Footer = () => {
  return (
    <Styled.Footer>
      <Styled.Container>
        <Logo />
        <Styled.FooterLinks>
          <Styled.Link
            href="/Regulamin-konkursu-BEEfor-z-charakterem.pdf"
            target={"_blank"}
          >
            Regulamin Konkursu
          </Styled.Link>
          <Styled.Link
            href="/Polityka-prywatnosci-Tullamore-DEW-Honey.pdf"
            target={"_blank"}
          >
            Polityka Prywatności
          </Styled.Link>
          <Styled.Button
            id="cc-settings"
            type="button"
            aria-label="Otwórz ustawienia cookies"
            data-cc="c-settings"
          >
            Zarządzanie cookies
          </Styled.Button>
          <Styled.Date>©{new Date().getFullYear()}</Styled.Date>
        </Styled.FooterLinks>
      </Styled.Container>
    </Styled.Footer>
  );
};

export default Footer;
