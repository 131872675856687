import React, { useRef, useEffect, ReactElement } from "react";
import * as Styled from "./HeaderSection.styled";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const HeaderSection = () => {
  const header = useRef<any>();
  const landscape = useRef<any>();
  const landscapeContainer = useRef<any>();
  const info = useRef<any>();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const headerSection = header.current;
    const landscapeSection = landscape.current;
    const infoContainer = info.current;
    if (!headerSection || !landscapeContainer || !infoContainer) {
      return;
    }
    const headerImage = headerSection.children[1];
    const headerBootle = headerSection.children[2];

    const elements = landscapeContainer.current.children;
    const landscapeText = elements[0];
    const landscapeBeeLarge = elements[1];
    const landscapeBeeSmall = elements[2];

    const infoContainerHeight = infoContainer.getBoundingClientRect().height;

    const landscapeScrollTrigger = {
      trigger: landscapeSection,
      start: "top top",
      end: "bottom top",
      scrub: true,
      pin: false,
      delay: 0,
    };

    gsap.fromTo(
      headerImage,
      { autoAlpha: 1, y: "+=0" },
      {
        autoAlpha: 0,
        y: `200vh`,
        scrollTrigger: {
          ...landscapeScrollTrigger,
          end: "bottom 85%",
          scrub: 0,
        },
      }
    );
    gsap.fromTo(
      landscapeText,
      { opacity: 1, autoAlpha: 1, y: "+=0" },
      {
        opacity: 0,
        autoAlpha: 0,
        y: "-=500",
        scrollTrigger: landscapeScrollTrigger,
      }
    );
    gsap.fromTo(
      landscapeBeeLarge,
      { opacity: 1, autoAlpha: 1, y: 0, x: "+=0" },
      {
        opacity: 0,
        autoAlpha: 0,
        y: "+=500",
        x: "-=1000",
        scrollTrigger: landscapeScrollTrigger,
      }
    );

    if (window.innerWidth > 576) {
      gsap.fromTo(
        landscapeBeeSmall,
        { opacity: 1, autoAlpha: 1, y: "+=0", x: "+=0" },
        {
          opacity: 0,
          autoAlpha: 0,
          y: "+=500",
          x: "+=1000",
          scrollTrigger: landscapeScrollTrigger,
        }
      );
      gsap.to(headerBootle, {
        ease: "none",
        scrollTrigger: {
          trigger: headerBootle,
          start: "50% 50%",
          end: `${infoContainerHeight + 200}px`,
          scrub: 0.25,
          pin: true,
        },
      });
    } else {
      gsap.to(headerBootle, {
        ease: "none",
        scrollTrigger: {
          trigger: headerBootle,
          start: "50% 70%",
          end: `${infoContainerHeight + 200}px`,
          scrub: 0.25,
          pin: true,
        },
      });
    }
  }, []);

  return (
    <Styled.Header id={"tullamore"} ref={header}>
      <Styled.Landscape id={"landscape"} ref={landscape}>
        <Styled.LandscapeContainer ref={landscapeContainer}>
          <Styled.LandscapeTextWrapper>
            <Styled.LandscapeTitle>
              Słodko i&nbsp;z&nbsp;charakterem?
            </Styled.LandscapeTitle>
            <Styled.LandscapeText>
              Daj się poznać od zaskakującej strony i&nbsp;wygraj!
            </Styled.LandscapeText>
          </Styled.LandscapeTextWrapper>
          <Styled.BeeLarge />
          <Styled.BeeSmall />
        </Styled.LandscapeContainer>
      </Styled.Landscape>

      <Styled.LandscapeImage />
      <Styled.Bottle id={"animatedBottle"} />

      <Styled.Info>
        <Styled.InfoContainer ref={info}>
          <Styled.BeeSmall className={"info-container"} />
          <Styled.InfoTitle>
            Zaskakująca <span>słodycz</span> z&nbsp;charakterem
          </Styled.InfoTitle>
          <Styled.InfoTextWrapper>
            <Styled.InfoColumn>
              <Styled.InfoSubtitle>Słodycz miodu</Styled.InfoSubtitle>
              <Styled.InfoText>
                W&nbsp;niezależnych, czeskich pasiekach powstaje najważniejszy
                składnik naszej Tullamore D.E.W. Honey - prawdziwy miód. To jemu
                zawdzięczamy jedwabistą, gładką słodycz i&nbsp;niepowtarzalny
                smak.
              </Styled.InfoText>
            </Styled.InfoColumn>
            <Styled.InfoColumn>
              <Styled.InfoSubtitle>Charakter Irlandii</Styled.InfoSubtitle>
              <Styled.InfoText>
                Tullamore D.E.W. Honey to zderzenie głębi i&nbsp;charakteru
                Tullamore D.E.W. Original z&nbsp;delikatnością i&nbsp;słodyczą
                prawdziwego miodu.
              </Styled.InfoText>
            </Styled.InfoColumn>
          </Styled.InfoTextWrapper>
        </Styled.InfoContainer>
      </Styled.Info>
    </Styled.Header>
  );
};

export default HeaderSection;
