import styled, { css } from "styled-components";
import ThemeStyle from "../../../styles/ThemeStyle";
import { mediaDown, mediaUp } from "../../../styles/Mixins";
import { AppContainer, LinkBase } from "../../../styles/BasicStyles";
import MenuBg from "../../../images/Common/menu-bg.png";

type StyledNavAnimatedProps = {
  isVisible: boolean;
};

export const NavStatic = styled.nav`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  .logo {
    margin-right: 3rem;
  }
`;

export const NavAnimated = styled.nav<StyledNavAnimatedProps>`
  width: 100%;
  margin: 0 auto;
  position: fixed;
  top: -12rem;
  left: 0;
  z-index: 900;
  overflow: hidden;
  -webkit-box-shadow: 0 6px 16px 0 rgba(244, 233, 201, 0.3);
  -moz-box-shadow: 0 6px 16px 0 rgba(244, 233, 201, 0.3);
  box-shadow: 0 6px 16px 0 rgba(244, 233, 201, 0.3);
  transition: top 0.5s;
  background: url("${MenuBg}") center/cover no-repeat;
  backdrop-filter: opacity(0.5);

  .logo {
    margin-right: 3rem;
  }

  ${(props) =>
    props.isVisible &&
    css`
      top: 0;
    `}
`;

const ContainerBase = css`
  ${AppContainer()};
  height: 12rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 1rem;
    `
  )}
`;

export const ContainerStatic = styled.div`
  ${ContainerBase};
  margin-top: 4rem;

  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      margin-top: 1rem;
    `
  )}
`;

export const ContainerAnimated = styled.div`
  ${ContainerBase};
  height: 6rem;
`;

const LogoWrapperBase = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  .logo {
    margin-right: 3rem;
    margin-left: 6rem;

    ${mediaDown(
      ThemeStyle.breakpoints.sm,
      css`
        margin-left: 1.5rem;
      `
    )}
  }
`;

export const LogoWrapperStatic = styled.div`
  ${LogoWrapperBase};

  .logo {
    ${mediaDown(
      ThemeStyle.breakpoints.sm,
      css`
        width: 8.5rem;
      `
    )}
  }
`;

export const LogoWrapperAnimated = styled.div`
  ${LogoWrapperBase};

  .logo {
    svg {
      width: 5rem;
    }
  }
`;

export const NavLink = styled(LinkBase)`
  font-size: 2rem;
  &:before {
    height: 0.2rem;
  }

  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      margin: 0 2rem;
    `
  )}
`;
export const LinksWrapper = styled.div`
  display: none;

  ${mediaUp(
    ThemeStyle.breakpoints.nav,
    css`
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      height: 100%;
      width: 100%;
      padding-right: 4rem;
    `
  )};
`;

export const MenuButton = styled.button`
  display: inline-block;
  border: none;
  outline: none;
  z-index: 1;

  ${mediaUp(
    ThemeStyle.breakpoints.nav,
    css`
      display: none;
    `
  )};
`;
