import React from "react";
import * as Styled from "./Nav.styled";
import BurgerMenuIcon from "../Icons/BurgerMenuIcon";
import Logo from "../../Logo/Logo";
import scrollToElement from "../../../functions/scrollToElement";

interface NavProps {
  isMenuOpen: boolean;
  handleMenuToggle: () => void;
}

const NavStatic: React.FunctionComponent<NavProps> = ({
  isMenuOpen,
  handleMenuToggle,
}) => {
  return (
    <Styled.NavStatic>
      <Styled.ContainerStatic>
        <Styled.LogoWrapperStatic>
          <Logo dark={true} width="128px" />
        </Styled.LogoWrapperStatic>
        <Styled.LinksWrapper>
          <Styled.NavLink
            onClick={(e) => scrollToElement(e, "tullamore")}
            href="#"
          >
            Tullamore D.E.W. honey
          </Styled.NavLink>
          <Styled.NavLink
            onClick={(e) => scrollToElement(e, "konkurs")}
            href="#"
          >
            Konkurs
          </Styled.NavLink>
          <Styled.NavLink
            onClick={(e) => scrollToElement(e, "nagrody")}
            href="#"
          >
            Nagrody
          </Styled.NavLink>
          <Styled.NavLink
            onClick={(e) => scrollToElement(e, "wez-udzial")}
            href="#"
          >
            Weź udział
          </Styled.NavLink>
          <Styled.NavLink
            onClick={(e) => scrollToElement(e, "koktajle")}
            href="#"
          >
            Koktajle
          </Styled.NavLink>
          <Styled.NavLink
            onClick={(e) => scrollToElement(e, "kontakt")}
            href="#"
          >
            Kontakt
          </Styled.NavLink>
        </Styled.LinksWrapper>
        <Styled.MenuButton onClick={handleMenuToggle}>
          <BurgerMenuIcon isMenuOpen={isMenuOpen} dark />
        </Styled.MenuButton>
      </Styled.ContainerStatic>
    </Styled.NavStatic>
  );
};

export default NavStatic;
