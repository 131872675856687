import styled from "styled-components";

type LogoProps = {
  width: string;
  dark: boolean;
};

export const Container = styled.div<LogoProps>`
  width: ${(props) => props.width};

  svg path {
    fill: ${(props) => (props.dark ? "black" : "white")};
  }
`;
