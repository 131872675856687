import React from "react";
import * as Styled from "./Nav.styled";
import Logo from "../../Logo/Logo";
import BurgerMenuIcon from "../Icons/BurgerMenuIcon";
import scrollToElement from "../../../functions/scrollToElement";

interface NavProps {
  isVisible: boolean;
  isMenuOpen: boolean;
  handleMenuToggle: () => void;
}

const NavClipOn: React.FunctionComponent<NavProps> = ({
  isVisible,
  isMenuOpen,
  handleMenuToggle,
}) => {
  return (
    <Styled.NavAnimated isVisible={isVisible}>
      <Styled.ContainerAnimated>
        <Styled.LogoWrapperAnimated>
          <Logo />
        </Styled.LogoWrapperAnimated>
        <Styled.LinksWrapper>
          <Styled.NavLink
            onClick={(e) => scrollToElement(e, "tullamore")}
            href="#"
          >
            Tullamore D.E.W. Honey
          </Styled.NavLink>
          <Styled.NavLink
            onClick={(e) => scrollToElement(e, "konkurs")}
            href="#"
          >
            Konkurs
          </Styled.NavLink>
          <Styled.NavLink
            onClick={(e) => scrollToElement(e, "nagrody")}
            href="#"
          >
            Nagrody
          </Styled.NavLink>
          <Styled.NavLink
            onClick={(e) => scrollToElement(e, "wez-udzial")}
            href="#"
          >
            Weź udział
          </Styled.NavLink>
          <Styled.NavLink
            onClick={(e) => scrollToElement(e, "koktajle")}
            href="#"
          >
            Koktajle
          </Styled.NavLink>
          <Styled.NavLink
            onClick={(e) => scrollToElement(e, "kontakt")}
            href="#"
          >
            Kontakt
          </Styled.NavLink>
        </Styled.LinksWrapper>
        <Styled.MenuButton onClick={handleMenuToggle}>
          <BurgerMenuIcon isMenuOpen={isMenuOpen} dark />
        </Styled.MenuButton>
      </Styled.ContainerAnimated>
    </Styled.NavAnimated>
  );
};

export default NavClipOn;
