import React from "react";

const scrollToElement = (
  event: React.MouseEvent<HTMLAnchorElement>,
  id: string
) => {
  event.preventDefault();
  if (!document) {
    return;
  }
  const el = document.getElementById(id);
  if (el) {
    el.scrollIntoView();
  }
};

export default scrollToElement;
