import React from "react";

type UseScrollResponse = {
  top: number;
  left: number;
  directionVertical?: "up" | "down" | undefined;
  directionHorizontal?: "left" | "right" | undefined;
};

const useScroll: () => UseScrollResponse = () => {
  const [state, setState] = React.useState<UseScrollResponse>({
    top: 0,
    left: 0,
  });

  const onScroll = React.useCallback(
    (event) => {
      if (!window) {
        return;
      }

      setState((p) => ({
        ...p,
        top: event.target.documentElement.scrollTop,
        left: event.target.documentElement.scrollLeft,
        directionVertical:
          event.target.documentElement.scrollTop > p.top
            ? "down"
            : event.target.documentElement.scrollTop < p.top
            ? "up"
            : undefined,
        directionHorizontal:
          event.target.documentElement.scrollLeft > p.left
            ? "right"
            : event.target.documentElement.scrollLeft < p.left
            ? "left"
            : undefined,
      }));
    },
    [state]
  );

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);

  return state;
};

export default useScroll;
